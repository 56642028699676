<script>
import simplebar from "simplebar-vue";
import config from "../config";
import axios from "axios";
import { userService, restaurantService, vendorService } from "../services";
import WhatsNew from '../views/whatsnew/whats-new';
import {
  get_partner_login,
  get_user,
  get_user_detail,
  get_partner_details,
  get_partner,
  set_user,
  set_user_detail,
  remove_get_user,
  remove_get_user_detail,
  remove_partner_login,
  remove_partner,
  remove_partner_token,
  remove_partner_details,
  set_partner_details,
  set_partner,
  replaceByDefault
} from "../Helper/helper";


export default {
  data() {
    return {
      replaceByDefault:replaceByDefault,
      config: config,
      restaurant_id: "",
      restaurant_on_off: "0",
    };
  },
  components: {
    simplebar,
    WhatsNew
  },
  mounted() {
    this.getRestaurantById();
  },
  methods: {
    getRestaurantById() {
      vendorService
        .getRestaurantById({ restaurant_id: this.$route.params.restaurant_id })
        .then((response) => {
          const {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots,
            restaurant_on_off
          } = response.data.result;
          this.restaurant_id = restaurant_id;
          this.restaurant_on_off = restaurant_on_off;
          this.is_confirm = is_confirm;
          this.restaurant = {
            name,
            restaurant_id,
            is_confirm,
            delivery_type_time_slots
          };
        });
    },
    onStatusChange($event){
      this.restaurant_on_off = ($event.target.checked?'1':'0')
      restaurantService.restaurantOnOff({
        restaurant_id:this.restaurant_id,
        restaurant_on_off: ($event.target.checked?'1':'0')
      }).then(response => {
        if(response.data.code == 200){
          success_message(response.data.message)
        }
      })
    },
    logout() {
      if (confirm("Are you sure you want to logout?")) {
        this.$auth.logout((name) =>this.$router.push({ name }).catch(()=>{}));
      }
    },
    getUser() {
      // console.log(get_partner_login(),get_partner());

      this.users = this.$auth.user;
      this.userdetail = this.$auth.setting;

      // if(get_partner_login() && !get_partner())
      // {
      //   userService.getUserDetail().then((response) => {
      //   this.users = response.data.users;
      //   this.userdetail = response.data.userDetail;
      //   set_partner_details(response.data.userDetail);
      //   set_partner(response.data.users);
      // });
      // }
      // else if(!get_user())
      // {
      // userService.getUserDetail().then((response) => {
      //   this.users = response.data.users;
      //   this.userdetail = response.data.userDetail;
      //   set_user_detail(response.data.userDetail);
      //   set_user(response.data.users);
      // });
      // }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      this.updateNavbarbg(!element.classList.contains('show'));
      element.classList.toggle("show");
    },
    updateNavbarbg(status = true) {
      let el = document.getElementsByClassName("topnav");
      if (el[0]) {
        el[0].style.background = (status) ? "white" : "#3f51b5";
        el[0].style.marginTop = (status) ? "50px" : "0px";
      }
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="row">
      <!-- <div id="migration_alert" class="col-sm-12 text-center" style="color: red;" v-if="!$auth.partner_login">
        We are migrating a server on Monday, 14th Nov 2022. System will work as normal but for some of third party integration we required some support from you. Please follow instruction you received on a mail. Contact Deonde support if you have any question.
      </div> -->
    </div>
    <div class="navbar-header">
      <div class="d-flex horizontal-image">
        <!-- LOGO -->
        <div class="navbar-brand-box" v-if="!$auth.partner_login && !$auth.partner_login && $auth.user.vendor_role != '1'">
         <a href="#" class="logo logo-dark" v-if="$auth.setting">
            <span class="logo-sm">
              <img :src="config.foodicon+$auth.setting.flat_icon" alt height="22"/>
            </span>
            <span class="logo-lg">
              <img :src="config.foodicon+$auth.setting.app_logo" alt height="35"/>
            </span>
          </a>

          <a href="#" class="logo logo-light" v-if="$auth.setting">
            <span class="logo-sm">
              <img :src="config.foodicon+$auth.setting.flat_icon" alt height="22"/>
            </span>
            <span class="logo-lg">
              <img :src="config.foodicon+$auth.setting.app_logo" alt height="25"/>
            </span>
          </a>

        </div>
        <div class="navbar-brand-box navbar-brand-m" v-else>
          <a href="#" class="logo logo-dark" v-if="$auth.setting">
            <span class="logo-sm">
              <img :src="config.foodicon+$auth.setting.flat_icon"  @error="replaceByDefault" alt  height="22"/>
            </span>
            <span class="logo-lg">
              <img :src="config.foodicon+$auth.setting.app_logo" @error="$event.target.src=config.no_image" alt  height="17"/>
            </span>
          </a>

          <a href="#" class="logo logo-light" v-if="$auth.setting">
            <span class="logo-sm">
              <img :src="config.foodicon+$auth.setting.flat_icon" alt height="22"/>
            </span>
            <span class="logo-lg">
              <img :src="config.foodicon+$auth.setting.app_logo" @error="$event.target.src=config.no_image" alt height="25"/>
            </span>
          </a>

        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
          style="display:none !important"
        >
          <!-- <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template> -->

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>
      <div v-if="!$auth.partner_login && this.$auth.user.vendor_role != '1'" class="d-none">
        <b-dropdown
          class="d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <router-link :to="{ name: 'orders-list' }" class="dropdown-icon-item">
                  <i class="bx bx-copy-alt"></i>
                  <span>Ordering</span>
                </router-link>
              </div>
              <div class="col">
               <router-link :to="{ name: 'vendor-list' }" class="dropdown-icon-item">
                  <i class="bx bx-store"></i>
                  <span>Vendors</span>
                </router-link>
              </div>
              <div class="col">
               <router-link :to="{ name: 'configurations' }" class="dropdown-icon-item">
                  <i class="bx bx-wrench"></i>
                  <span>Configuration</span>
                </router-link>
              </div>
            </div>

            <div class="row no-gutters d-none">
              <div class="col">
                 <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img :src="config.brandicon+'dropbox.png'" alt="dropbox" />
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
               <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img :src="config.brandicon+'mail_chimp.png'" alt="mail_chimp" />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img :src="config.brandicon+'slack.png'" alt="slack" />
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>
      <div v-if="!$auth.partner_login && this.$auth.user.vendor_role != '1'">
        <div class="px-lg-2">
          <div class="row header-menu mobile-none">
            <div class="col" v-if="$auth.hasPermission(config.permissions.order_read) || $auth.hasPermission(config.permissions.order_write)">
              <router-link :to="{ name: 'orders-list' }" class="dropdown-icon-item">
                <span v-b-tooltip.hover title="Order">
                  <i class="bx bx-copy-alt"></i>
                </span>
              </router-link>
            </div>
            <div class="col" v-if="$auth.hasPermission(config.permissions.merchant_read) || $auth.hasPermission(config.permissions.merchant_write)">
             <router-link :to="{ name: 'vendor-list' }" class="dropdown-icon-item">
                <span v-b-tooltip.hover :title="$auth.setting.restaurant_name">
                  <i class="bx bx-store"></i>
                </span>
              </router-link>
            </div>
            <div class="col" v-if="$auth.hasPermission(config.permissions.promo_code_read) || $auth.hasPermission(config.permissions.promo_code_write)">
             <router-link :to="{ name: 'coupon-list' }" class="dropdown-icon-item">
               <span v-b-tooltip.hover title="Promocode">
                <i class="bx bx-purchase-tag"></i>
               </span>
              </router-link>
            </div>
            <div class="col" v-if="$auth.hasPermission(config.permissions.configuration_read) || $auth.hasPermission(config.permissions.configuration_write)">
             <router-link :to="{ name: 'configurations' }" class="dropdown-icon-item">
               <span v-b-tooltip.hover title="Configuration">
                <i class="bx bx-wrench"></i>
               </span>
              </router-link>
            </div>
          </div>

          <div class="row no-gutters d-none">
            <div class="col">
               <a class="dropdown-icon-item" href="javascript: void(0);">
                <!-- <img :src="config.brandicon+'dropbox.png'" alt="dropbox" /> -->
                <span>Dropbox</span>
              </a>
            </div>
            <div class="col">
             <a class="dropdown-icon-item" href="javascript: void(0);">
                <!-- <img :src="config.brandicon+'mail_chimp.png'" alt="mail_chimp" /> -->
                <span>Mail Chimp</span>
              </a>
            </div>
            <div class="col">
              <a class="dropdown-icon-item" href="javascript: void(0);">
                <!-- <img :src="config.brandicon+'slack.png'" alt="slack" /> -->
                <span>Slack</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex">
        <div class="px-lg-3">
          <div class="row header-menu mobile-none">
            <div class="col">
              <router-link :to="{ name: 'table-view' }" class="dropdown-icon-item">
                <span v-b-tooltip.hover><i class="bx bx-box"></i>POS</span>
              </router-link>
            </div>
          </div>
        </div>
        <form class="app-search d-none d-lg-block mt-1" v-if="$auth.user.vendor_role != '1'">
          <div class="position-relative">
           <label class="switch">
              <input
                type="checkbox"
                id="status"
                name="status"
                v-model="restaurant_on_off"
                class="switch-on"
                true-value="1"
                false-value="0"
                @change="onStatusChange"
              />
              <div class="slider round">
                <span class="on">{{ trans.get('__JSON__.Yes') }}</span>
                <span class="off">{{ trans.get('__JSON__.No') }}</span>
              </div>
            </label>
          </div>
        </form>
      </div>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

      <div v-if="this.$auth.user.vendor_role != '1' && $auth.hasPermission(config.permissions.order_write)">
        <b-dropdown
          lazy
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span
              class="badge badge-danger badge-pill" v-if="$auth.orders.length > 0"
            >{{$auth.orders.length}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{trans.get('__JSON__.Notifications')}}</h6>
              </div>
              <div class="col-auto">
                <router-link :to="{name: 'orders-list'}"  class="small">{{ trans.get('__JSON__.View All') }}</router-link>
              </div>
            </div>
          </div>
           <simplebar data-simplebar style="max-height: 230px;" v-if="$auth.orders.length > 0">
            <router-link class="text-reset notification-item" v-for="order in $auth.orders" :key="order.id"  :to="{name: 'orders-list', query: { status: (order.future_delivery_date!='0000-00-00 00:00:00' && order.order_status=='InKitchen')?'Scheduled':'Placed',date:moment(order.created_at).format('YYYY-MM-DD') }}">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ trans.get('__JSON__.New Order Placed', {order_id:'#'+order.id} ) }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> {{moment(order.created_at).fromNow()}}
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </simplebar>
          <simplebar data-simplebar style="max-height: 230px;" v-else>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ trans.choice('No Orders Found') }}</h6>
                </div>
              </div>
            </a>
          </simplebar>
        </b-dropdown>
      </div>
        <!-- <b-dropdown
        
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span
              class="badge badge-danger badge-pill"
            >3</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">View All</a>
              </div>
            </div>
          </div>
           <simplebar data-simplebar style="max-height: 230px;">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">If several languages coalesce the grammar</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  :src="config.basepath+'avatar-3.jpg'"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">James Lemire</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">It will seem like simplified English.</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 1 hours ago
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Your item is shipped</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">If several languages coalesce the grammar</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  :src="config.basepath+'avatar-4.jpg'"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Salena Layfield</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 1 hours ago
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              Load More..
            </a>
          </div>
        </b-dropdown> -->

      <div v-if="$auth.partner_login">
        <b-dropdown right variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="config.restauranticon+$auth.user.restaurant.icon_image"
              @error="replaceByDefault"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1">{{$auth.user.user_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <router-link :to="{name: 'vendor.profile'}" class="dropdown-item">
            <i class="bx bx-user font-size-16 align-middle mr-1"></i> My Profile
          </router-link>
          <!-- item-->
          <router-link :to="{name: 'change-password'}" class="dropdown-item">
            <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> Change Password
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
          </a>
        </b-dropdown>
      </div>
       <div v-else-if="this.$auth.user.vendor_role == '1'">
          <b-dropdown right variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
            <template v-slot:button-content>
              <img
                class="rounded-circle header-profile-user"
                :src="config.foodicon+$auth.setting.flat_icon"
                @error="replaceByDefault"
                alt="Header Avatar"
              />
              <span class="d-none d-xl-inline-block ml-1">{{$auth.user.vendor_name}}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </template>
            <!-- item-->
            <router-link :to="{name: 'change-password'}" class="dropdown-item">
              <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> Change Password
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
              <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
            </a>
          </b-dropdown>
         </div>
         <div v-else>
        <b-dropdown right variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="config.foodicon+$auth.setting.flat_icon"
              @error="replaceByDefault"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1">{{$auth.user.vendor_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <router-link :to="{name: 'business-profile'}" class="dropdown-item" v-if="$auth.user.parent_vendor_id == null">
            <i class="bx bx-user font-size-16 align-middle mr-1"></i>Business Profile
          </router-link>
          <router-link :to="{name: 'subscription-info'}" class="dropdown-item" v-if="$auth.user.parent_vendor_id == null">
            <i class="bx bx-wallet font-size-16 align-middle mr-1"></i> My Plan
          </router-link>
          <router-link :to="{name: 'billing'}" class="dropdown-item d-none">
            <i class="bx bx-purchase-tag-alt font-size-16 align-middle mr-1"></i> My Billing
          </router-link>
          <router-link :to="{name: 'change-password'}" class="dropdown-item">
            <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> Change Password
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
          </a>
        </b-dropdown>
         <!-- <div class="dropdown d-inline-block" v-if="!$auth.partner_login">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div> -->
    </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
  .header-menu {
    padding-top: 7px;
  }
  .header-menu i{
      font-size: 22px;
  }
  .header-menu .dropdown-icon-item:hover{
    border-color: #ffffff;
  }
  .header-menu .dropdown-icon-item{
    border: none;
    color: #e9ecef;
  }
  .header-menu a:hover{
    color: #e9ecef;
  }
  body[data-layout=horizontal][data-topbar=light] .header-menu .dropdown-icon-item{
    color: #555b6d;
  }
  @media all and (max-width:991px){
    .navbar-brand-box {
      display: none !important;
    }
  }
</style>