
// Report services rest api calling

import { routes } from '../config/api_routes'

export const reportService = {
    // get sales report 

    getSalesReport: (formData) => {    
        return window.axios.post(routes.getSalesReportApi,formData)
    }, 
    
    // sales report export to csv
    exportToCsv: (formData) => {
        return window.axios.post(routes.saleReportExportToCsvApi,formData)
    },

    // get item report 

    getItemReport: (formData) => {    
        return window.axios.post(routes.getItemReportApi,formData)
    }, 

    // get Customer Report

    getCustomerReport: (formData) => {
        return window.axios.post(routes.getCustomerReportApi,formData)
    },

    // Customer Export To CSV

    customerExportToCSV: (formData) => {
        return window.axios.post(routes.reportcustomerExportToCsvApi,formData)
    },

    // get Total Sale report
    gettotalSaleReport: (formData) => {
        return window.axios.post(routes.totalSaleReportApi,formData)
    },

    // Total Sale Export To CSV
    totalSalesExportToCSV: (formData) => {
        return window.axios.post(routes.totalSaleReportExportToCsvApi,formData)
    },

    // get All Restaurants
    getAllRestaurantName: (formData) => {
        return window.axios.post(routes.getRestaurantsApi, formData)
    },

    // get All Restaurants Confirm
    getRestaurantsConfirm: (formData) => {
        return window.axios.post(routes.getRestaurantsConfirmApi,formData)
    },  

    // get GST Admin commission Report
    getTaxReport: (formData) => {
        return window.axios.post(routes.taxReportReportApi,formData)
    },

    // Gst Admin Export To CSV
    taxReportExportToCsv: (formData) => {
        return window.axios.post(routes.taxReportExportToCsvApi,formData)
    },

    // get All User
    getAllUser: () => {
        return window.axios.get(routes.getAllUserApi)
    },

    // get DriverReportListing
    getDriverReportListing: (formData) => {
        return window.axios.post(routes.getDriverReportApi,formData)
    },
    // To export driver data in csv
    driverReportExportToCsv: (formData) => {
        return window.axios.post(routes.getDriverReportCsvApi,formData)  
    },

    // get InactiveCustomerReport
    getInactiveCustomerReport: (formData) => {
        return window.axios.post(routes.getInactiveCustomerReportApi,formData)
    },

    // Export InactiveCustomerReport
    exportInactiveCustomer: (formData) => {
        return window.axios.post(routes.exportInactiveCustomerApi,formData)
    },

    // get CODOnlineorderReport
    getCODOnlineOrderReport: (formData) => {
        return window.axios.post(routes.getCODOnlineOrderReportApi,formData)
    },

    // export To Csv CodOnlineReport
    exportOnlineReport: (formData) => {
        return window.axios.post(routes.exportCODOnlineReportApi,formData)
    },

    // get Vendor Rating Report
    getVendorAvgRatingReport: (formData) => {
        return window.axios.post(routes.getVendorRatingReportApi,formData)
    },

    // Vendor Avg Export To Csv
    exportVendorAvgRating: (formData) => {
        return window.axios.post(routes.exportVendorAvgRatingApi,formData)
    },

    // get GST Delivery Charge commission Report
    getGstDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.getGSTDeliveryChargeReportApi,formData)
    },

    // Export To Csv GST delivery Charge
    exportGSTDeliveryChargeReport: (formData) => {
        return window.axios.post(routes.exportGSTDelivertReportApi,formData)
    },

    getPromoCodeReport: (formData) => {
        return window.axios.post(routes.getPromoCodereportApi,formData)
    },

    // Get Sales Vendor Report
    getVendorSalesReport: (formData) => {
        return window.axios.post(routes.getVendorSalesReportApi,formData)
    },

    // Get Vendor Rating Review Report
    getVendorRatingReview: (formData) => {
        return window.axios.post(routes.getVendorRatingReviewReportApi,formData)
    },

    // Export Vendor Rating Review Report
    exportVendorRatingReview: (formData) => {
        return window.axios.post(routes.exportVendorRatingReviewReportApi,formData)
    },

    // Get Driver Rating Review Report
    getDriverRatingReview: (formData) => {
        return window.axios.post(routes.getDriverRatingReviewReportApi,formData)
    },

    // Export Driver Rating Review Report
    exportDriverRatingReview: (formData) => {
        return window.axios.post(routes.exportDriverRatingReviewReportApi,formData)
    }
    ,
    getWalletReport:(data)=>{
        return window.axios.post(routes.getWalletReportApi,data)
    },
    getWalletReportExport:(data)=>{
        return window.axios.post(routes.getWalletReportExportApi,data)
    },
    getDriverSettlementReport:(data)=>{
        return window.axios.post(routes.getDriverSettlementApi,data)
    },
    exportDriverSettlementToCsv: (formData) => {
        return window.axios.post(routes.driveSettlementExportToCsvApi,formData)
    },

    // Get Restaurant QR Feedback Report
    getVendorQrFeedbackReport:  (formData) => {
        return window.axios.post(routes.getVendorQrFeedbackReportApi,formData)
    },
    
    // Export Restaurant QR Feedback Report
    getVendorQrFeedbackReportExport:  (formData) => {
        return window.axios.post(routes.getVendorQrFeedbackReportExportApi,formData)
    }
}