<script>
import config from "../config";

export default {
    data(){
        return {
              config
        };
    },
    methods:{
      loadContent(){
        var tripetto = TripettoServices.init({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiTmJza3FDbVRRalVPamhRcnhISmhwc0hEMDg1d2NPNlQzbEtHUlovZUNmbz0iLCJkZWZpbml0aW9uIjoiWnBpNjJscjdhQ1NXTHhYalJxSlFyVk1DdEhPdEMwTjkxR3hLczhieEFpaz0iLCJ0eXBlIjoiY29sbGVjdCJ9.QlLyRV5uZFPd8Qn1IJmpDSfEX–kvpAtworxi3HxPaM" });
        TripettoAutoscroll.run({
        element: document.getElementById("tripetto"),
        definition: tripetto.definition,
        styles: tripetto.styles,
        l10n: tripetto.l10n,
        locale: tripetto.locale,
        translations: tripetto.translations,
        attachments: tripetto.attachments,
        onSubmit: tripetto.onSubmit
        });
      }
    }
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{trans.get('__JSON__.Copyright')}} © {{ new Date().getFullYear() }} {{ trans.get('Deonde') }} {{trans.get('__JSON__.All rights reserved')}}.</div>
        <div v-if="!$auth.partner_login">
          <div class="col-sm-6 text-right d-none">
              <b-button variant="primary" pill v-b-modal.modal-1 >Demo</b-button>
              <b-modal id="modal-1" size="lg" title="Demo" @shown="loadContent">
                <div id="tripetto"></div>
              </b-modal>
          </div>
        </div>
        <!-- <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">Design & Develop by {{config.appname}}</div>
        </div> -->
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
