
// Client services rest api calling

import { routes } from '../config/api_routes'

export const clientService = {
    // get clientList 

    getClientListing: (formData) => {    
        return window.axios.post(routes.getClientApi,formData)
    },

    addClient: (formData) => {
        return window.axios.post(routes.addClientApi,formData)
    },

    getClientById: (formData) => {
        return window.axios.post(routes.getClientByIdApi,formData)
    },

    updateClient: (formData) => {
        return window.axios.post(routes.updateClientApi,formData)
    },

    updateClientStatus: (formData) => {
        return  window.axios.post(routes.updateClientStatusApi,formData)
    },

    getProductBusinessTypes: (formData) => window.axios.get(routes.getProductBusinessTypes,formData),

    getClientCount: () => {
        return window.axios.get(routes.getClientCountApi)
    },

    getClientReport: (formData) => window.axios.post(routes.getClientReportApi,formData),

    clientReportExportCsv: (formData) => window.axios.post(routes.clientReportExportCsvApi, formData)

}