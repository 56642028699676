import config from '../config'

export default {
    config: config,
}
const permissions = config.permissions;
const base = config.base_folder
export const menuItems = [
    {
        id: 1,
        icon: "bx-home-circle",
        label: "Dashboard",
        parentId: 1,
        link: base + "dashboard",
        required_permission: permissions.dashboard_read,
        permissions: [permissions.dashboard_read]

    },
    {
        id: 2,
        icon: "bx-copy-alt",
        label: "Order",
        link: base + "orders/list",
        required_permission: permissions.order_read,
        permissions: [permissions.order_read, permissions.order_write],

    },
    {
        id: 3,
        icon: "bx-user",
        label: "Driver",
        link: base + "driver/dashboard",
        required_permission: permissions.dashboard_driver_read,
        permissions: [permissions.dashboard_driver_read]

    },
    {
        id: 4,
        label: "Vendor",
        icon: "bx-home-circle",
        badge: {
            'variant': 'danger',
            "text": 'vendors'
        },
        subItems: [
            {
                id: 5,
                label: "Vendor",
                link: base + "vendors",
                parentId: 4,
                required_permission: permissions.merchant_read,
                permissions: [permissions.merchant_read, permissions.merchant_write]
            },
            {
                id: 6,
                label: "Brand",
                link: base + 'brand',
                parentId: 4,
                required_permission: permissions.brand_managment_read,
                permissions: [permissions.brand_managment_read, permissions.brand_managment_write]
            },
            {
                id: 7,
                label: "Cuisines",
                link: base + "cuisines",
                parentId: 4,
                required_permission: permissions.cuisine_read,
                permissions: [permissions.cuisine_read, permissions.cuisine_write]
            },
            {
                id: 8,
                label: "Item Tag",
                link: base + "tag",
                parentId: 4,
                required_permission: permissions.tag_read,
                permissions: [permissions.tag_read, permissions.tag_write]
            },
        ]
    },
    {
        id: 9,
        icon: "bx-user",
        label: "Customer",
        link: base + "customer",
        required_permission: permissions.customer_read,
        permissions: [permissions.customer_read, permissions.customer_write]

    },
    {
        id: 10,
        label: "Delivery",
        icon: "bx-map",
        subItems: [
            {
                id: 11,
                label: "Driver",
                link: base + "drivers",
                parentId: 10,
                required_permission: permissions.driver_read,
                permissions: [permissions.driver_read, permissions.driver_write]
            },
            {
                id: 12,
                label: "Eagle View",
                link: base + "eagle-view/list",
                parentId: 10,
                required_permission: permissions.eagle_view_read,
                permissions: [permissions.eagle_view_read]
            },
            {
                id: 13,
                label: "Location",
                link: base + "location",
                parentId: 10,
                required_permission: permissions.location_read,
                permissions: [permissions.location_read, permissions.location_write]
            },
            {
                id: 14,
                label: "Delivery Zones",
                link: base + "delivery_zone",
                parentId: 10,
                required_permission: permissions.delivery_zone_read,
                permissions: [permissions.delivery_zone_read, permissions.delivery_zone_write]
            }
        ]
    },
    {
        id: 15,
        label: "Promotions",
        icon: "bx-purchase-tag",
        subItems: [
            {
                id: 16,
                label: "Promocode",
                link: base + "coupon",
                parentId: 15,
                required_permission: permissions.promo_code_read,
                permissions: [permissions.promo_code_read, permissions.promo_code_write]
            },
            {
                id: 17,
                label: "Banners",
                link: base + "banner",
                parentId: 15,
                required_permission: permissions.banner_read,
                permissions: [permissions.banner_read, permissions.banner_write]
            },
            {
                id: 18,
                label: "Sales Channel",
                link: base + "sales-channels",
                parentId: 15,
                required_permission: permissions.sales_channel_read,
                permissions: [permissions.sales_channel_read, permissions.sales_channel_write]
            },
        ]
    },
    {
        id: 19,
        icon: "bx-chat",
        label: "Chat",
        link: base + "chat",
        required_permission: permissions.chat_read,
        permissions: [permissions.chat_read, permissions.chat_write],
        badge: {
            'variant': 'danger',
            "text": ['chat']
        },

    },
    {
        id: 20,
        label: "Analytics",
        icon: "bx-file",
        subItems: [
            {
                id: 21,
                label: "Vendor",
                link: base + "finance/vendor",
                parentId: 20,
                required_permission: permissions.vendor_finance_read,
                permissions: [permissions.vendor_finance_read, permissions.vendor_finance_write]
            },
            {
                id: 22,
                label: "Drivers",
                link: base + "finance/driver",
                parentId: 20,
                required_permission: permissions.driver_finance_read,
                permissions: [permissions.driver_finance_read, permissions.driver_finance_write]
            },
            {
                id: 23,
                label: "Reports",
                link: base + "reports",
                parentId: 20,
                required_permission: permissions.reports_read,
                permissions: [permissions.reports_read, permissions.reports_write]
            }
        ]
    },
    {
        id: 35,
        label: "Call Center",
        icon: "bx-phone",
        link: base+"call-center/dashboard",
        required_permission: permissions.call_center_read,
        permissions: [permissions.call_center_read,permissions.call_center_write]
    }, 
    {
        id: 37,
        label: "Review",
        icon: "bx-star",
        link: base+"review/list",
        required_permission: permissions.review_read,
        permissions: [permissions.review_read,permissions.review_write]
        // subItems: [
        //     // {
        //     //     id: 38,
        //     //     label: "Vendor",
        //     //     link: base+"review/vendor",
        //     //     parentId: 37,
        //     //     required_permission: permissions.review_read,
        //     //     permissions: [permissions.review_read,permissions.review_write]
        //     // },
        //     // {
        //     //     id: 39,
        //     //     label: "Driver",
        //     //     link: base+"review/driver",
        //     //     parentId: 37,
        //     //     required_permission: permissions.review_read,
        //     //     permissions: [permissions.review_read,permissions.review_write]
        //     // },
        //     // {
        //     //     id: 38,
        //     //     label: "Review",
        //     //     link: base+"review/list",
        //     //     parentId: 37,
        //     //     required_permission: permissions.review_read,
        //     //     permissions: [permissions.review_read,permissions.review_write]
        //     // }
        // ]
       
    },
    {
        id: 24,
        label: "Settings",
        icon: "bx-wrench",
        subItems: [
            {
                id: 25,
                label: "Configurations",
                link: base + "settings/configurations",
                parentId: 24,
                required_permission: permissions.configuration_read,
                permissions: [permissions.configuration_read, permissions.configuration_write]
            },
            {
                id: 26,
                label: "App Layout Settings",
                link: base + "settings/app-layout-setting",
                parentId: 24,
                required_permission: permissions.layout_read,
                permissions: [permissions.layout_read, permissions.layout_write]
            },
            {
                id: 27,
                label: "Web Configurations",
                link: base + "settings/web-layout-setting",
                parentId: 24,
                required_permission: permissions.web_layout_read,
                permissions: [permissions.web_layout_read, permissions.web_layout_write]
            },
            {
                id: 28,
                label: "User & Access",
                link: base + "settings/user-access",
                parentId: 24,
                required_permission: permissions.user_access_read,
                permissions: [permissions.user_access_read, permissions.user_access_write]

            },
            {
                id: 29,
                label: "Terminology",
                link: base + "settings/terminology",
                parentId: 24,
                required_permission: permissions.terminology_read,
                permissions: [permissions.terminology_read, permissions.terminology_write]
            },
            {
                id: 30,
                label: "Business Category",
                link: base + "category",
                parentId: 24,
                required_permission: permissions.category_read,
                permissions: [permissions.category_read, permissions.category_write]

            },
            {
                id: 31,
                label: "Email Template",
                link: base + "mails",
                parentId: 24,
                required_permission: permissions.email_template_read,
                permissions: [permissions.email_template_read, permissions.email_template_write]
            },
            {
                id: 32,
                label: "Pages",
                link: base + "content-page",
                parentId: 24,
                required_permission: permissions.content_pages_read,
                permissions: [permissions.content_pages_read, permissions.content_pages_write]
            },
            {
                id: 33,
                label: "FAQ",
                link: base + "faq",
                parentId: 24,
                required_permission: permissions.faq_read,
                permissions: [permissions.faq_read, permissions.faq_write]
            },
            {
                id: 34,
                label: "Logs",
                link: base + "system/list",
                parentId: 24,
                required_permission: permissions.logs_read,
                // permissions: [permissions.logs_read,permissions.logs_write]
                permissions: [permissions.logs_read]
            },
            // {
            //     id: 35,
            //     label: "Call Center",
            //     link: base+"call-center/dashboard",
            //     parentId: 24,
            //     required_permission: permissions.call_center_read,
            //     permissions: [permissions.call_center_read,permissions.call_center_write]
            // }, 

        ]
    },
    // {
    //     id: 36,
    //     label: "Notification",
    //     icon: "bx-broadcast",
    //     subItems: [
    //         // {
    //         //     id: 37,
    //         //     label: "Send Notification",
    //         //     icon: "bx-broadcast",
    //         //     link: base + "send-notification",
    //         //     required_permission: permissions.send_notification_read,
    //         //     permissions: [permissions.send_notification_read,permissions.sendNotification_write],
    //         // },
    //         {
    //             id: 37,
    //             label: "Notification History",
    //             link: base + "send_notification",
    //             parentId: 36,
    //             required_permission: permissions.send_notification_read,
    //             permissions: [permissions.send_notification_read,permissions.send_notification_write]

    //         },
    //     ]
    // },
    {
        id: 36,
        label: "Notification History",
        icon: "bx-broadcast",
        link: base + "send-notification",
        required_permission: permissions.send_notification_read,
        permissions: [permissions.send_notification_read,permissions.sendNotification_write],
    }
];
