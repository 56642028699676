
let instance;

import { error_message, remove_partner_token, remove_token, set_axios_defaults,set_partner_token, set_partner_login,set_token, get_token, get_partner_token, success_message } from '../Helper/helper';
import config from './../config'
import { authService, userService, chatService } from './../services';
import { menuItems } from "./../components/menu";
import { saasmenuItems } from "./../components/saas";
import router from '../views';

import * as VueGoogleMaps from "vue2-google-maps";

export const useAuth = (options = {}) => {
  if (instance) return instance;
  instance = new Vue({
    data() {
      return {
        user: null,
        setting: null,
        partner_login: true,
        languages: [],
        orders: [],
        logging_done: false,
        unreadMessages: [],
        received: 0,
      }
    },
    methods: {
      clientlogin(request, callback) {
        authService.clientLogin(request).then((response) => {
          let token = response.data.result.token;
          if (token) {
            // remove_token();
            localStorage.clear();
            this.user = response.data.result.user;
            this.setting = response.data.result.userDetail;
            this.logging_done = true;
            if (response.data.result.languages) {
              this.setLanguages(response.data.result.languages)
            }
            set_token(token)
            set_axios_defaults(token)
            let menuname = 'dashboard';
            let checkdashboard = this.user.access.find(d => d == "Dashboard Read");
            if (!checkdashboard) {
              let notinMenu = [];
              if (!this.isCallcenterActive()) {
                notinMenu.push(config.permissions.call_center_read);
              }
              if (!this.isChatActive()) {
                notinMenu.push(config.permissions.chat_read);
              }
              if (!this.isWebOrderingActive()) {
                notinMenu.push(config.permissions.web_layout_read);
              }
              let firstuseraccessread = this.user.access.filter(p => (p.includes('Read') && !notinMenu.includes(p)));
              if (firstuseraccessread) {
                let menu = menuItems.reduce((a, m) => {
                  if (m.subItems != undefined) {
                    var firstaccess = m.subItems.find(si => si.permissions.includes(firstuseraccessread[0]));
                  } else {
                    var firstaccess =  m.permissions.includes(firstuseraccessread[0]);
                    if(firstaccess){
                      firstaccess = m;
                    }
                  }
                  if (firstaccess) {
                    return firstaccess.link;
                  }
                  return a;
                }, null);
                if (menu) {
                  menuname = router.options.routes.find(r => r.path == menu).name;
                }
              }
            }
            // if(!this.partner_login){
            //   FreshworksWidget('show');
            // }

            window.maps = undefined;
            var googleMapScript = document.createElement('SCRIPT');
            googleMapScript.setAttribute('src', '//maps.googleapis.com/maps/api/js?key='+this.setting.google_map_key+'&libraries=drawing,places');
            googleMapScript.setAttribute('async', '');
            googleMapScript.setAttribute('defer', '');
            document.head.appendChild(googleMapScript);

            window.google_map_key = this.setting.google_map_key;

            window.Vue
            window.Vue.use(VueGoogleMaps, {
              load: {
                key: this.setting.google_map_key,
                libraries: "places,drawing",
              },
              installComponents: true,
            });

            callback(menuname)
          } else {
            error_message(response.data.message);
          }
        });
      },
      login(request, callback) {
        authService.loginUser(request).then((response) => {
          let token = response.data.result.token;
          /* remove_get_user();
          remove_get_user_detail();
          remove_partner_login();
          remove_partner();
          remove_partner_token();
          remove_partner_details(); */
          if (this.partner_login) {
            set_partner_login();
          }
          if (token) {
            this.user = response.data.result.user;
            this.setting = response.data.result.userDetail;
            this.logging_done = true;
            if(response.data.result.languages){
              this.setLanguages(response.data.result.languages)
            }
            if (this.partner_login) {
              set_partner_token(token)
            } else {
              set_token(token)
            }
            set_axios_defaults(token)
            // var permission = this.user.access.find(per => per.includes('Read'));
            // menuItems.reduce((a, item) => item)
            // menuItems.find(per => per.subItems.filter(p => p.required_permission.includes('Read')))
            let menuname = 'dashboard';
            if (!this.partner_login) {
              if(!this.isSaasClientLogin()){
                let checkdashboard = this.user.access.find(d => d == "Dashboard Read");
                if (!checkdashboard) {
                  let notinMenu = [];
                  if (!this.isCallcenterActive()) {
                    notinMenu.push(config.permissions.call_center_read);
                  }
                  if (!this.isChatActive()) {
                    notinMenu.push(config.permissions.chat_read);
                  }
                  if (!this.isWebOrderingActive()) {
                    notinMenu.push(config.permissions.web_layout_read);
                  }
                  let firstuseraccessread = this.user.access.filter(p => (p.includes('Read') && !notinMenu.includes(p)));
                  if (firstuseraccessread) {
                    let menu = menuItems.reduce((a, m) => {
                      if(m.subItems != undefined){
                      var firstaccess = m.subItems.find(si => si.permissions.includes(firstuseraccessread[0]));
                      }else{
                        var firstaccess =  m.permissions.includes(firstuseraccessread[0]);
                        if(firstaccess){
                          firstaccess = m;
                        }
                      }
                      if (firstaccess) {
                        return firstaccess.link;
                      }
                      return a;
                    }, null);
                    if (menu) {
                      menuname = router.options.routes.find(r => r.path == menu).name;
                    }
                  }
                }
              }
            }
            // if(!this.partner_login){
            //   FreshworksWidget('show');
            // }

            window.maps = undefined;
            
            var googleMapScript = document.createElement('SCRIPT');
            googleMapScript.setAttribute('src', '//maps.googleapis.com/maps/api/js?key='+this.setting.google_map_key+'&libraries=drawing,places');
            googleMapScript.setAttribute('async', '');
            googleMapScript.setAttribute('defer', '');
            document.head.appendChild(googleMapScript);

            window.google_map_key = this.setting.google_map_key;

            window.Vue

            window.Vue.use(VueGoogleMaps, {
              load: {
                key: this.setting.google_map_key,
                libraries: "places,drawing",
              },
              installComponents: true,
            });
            if(this.isSaasClientLogin()){
                callback('saas-client')
            }else{
              callback(this.partner_login ? 'vendor.dashboard' : menuname)
            } 
            // callback(this.partner_login ? 'vendor.dashboard' : 'dashboard')
          }
          else {
            error_message(response.data.message);
          }
        });
      },
      logout(callback) {
        authService.logout()
        .then(res => {
          // this.hideshowBeamer(false);
          // window.$('#beamerSelector').hide();
          // FreshworksWidget('hide');
          this.logging_done = false;
          if (this.partner_login) {
            remove_partner_token();
            callback('vendor.login')
          } else {
            remove_token();
            callback('login')
          }
        });
      },
      setUser(callback,token = "") {
        if(token == ""){
          this.checkLoginType()
        }
        if(this.checkLoginType()){
          this.logging_done = true;
          userService.getUserDetail()
            .then(response => {
              this.user = response.data.users;
              this.setting = response.data.userDetail;
              if(response.data.languages){
                this.setLanguages(response.data.languages)
              }
              // if(!this.partner_login){
              //   FreshworksWidget('show');
              // }
              // if (this.$auth.user.is_white_labeling == '0') {
              //   this.hideshowBeamer(true);
              // } else {
              //   this.hideshowBeamer(false);
              // }
              if(this.isSaasClientLogin()){
                callback('saas-client')
              }else{
                callback(this.partner_login?'vendor.dashboard':'dashboard')
              }
            })
        }else{
          this.logging_done = false;
          callback(this.partner_login?'vendor.login':'login')
        }
      },
      // hideshowBeamer(status){
      //   let currentroute = ['/permission'];
      //   if(currentroute.includes(window.location.pathname) && status){
      //     status = false;
      //   }
      //   if (status) {
      //     if (window.$('#beamerSelector').length > 0) {
      //       window.$('#beamerSelector').show();
      //     } else {
      //       let googleMapScript = document.createElement('SCRIPT');
      //       googleMapScript.setAttribute('src', '//app.getbeamer.com/js/beamer-embed.js');
      //       document.head.appendChild(googleMapScript);
      //     }
      //   } else {
      //     if (window.$('#beamerSelector').length > 0) {
      //       window.$('#beamerSelector').hide();
      //     }
      //   }
      // },
      checkLoginType() {
        let path = window.location.href;
        let token = get_token();
        let ptoken = get_partner_token();

        let type = path.replace(config.appurl, '').split('/')[0];
        this.partner_login = false;
        if (type == 'vendor') {
          set_axios_defaults(ptoken)
          this.partner_login = true;
          return !!ptoken
        }else{
          set_axios_defaults(token)
          return !!token
        }
        // return !!(token || ptoken);
      },
      updatePermissions(permissions){
        this.user = {
          ...this.user,
          access: permissions
        }
      },
      getVendorId(){
        return !this.user.parent_vendor_id?this.user.vendor_id:this.user.parent_vendor_id;
      },
      getCurrentUserId(){
        return this.user.vendor_id;
      },
      setLanguages(languages){
        this.langauges = languages;
      },
      getLangauge(abbr){
        return this.langauges.find(l => l.abbrivation == abbr);
      },
      updateOrders(orders){
        this.orders = orders
      },
      hasPermission(permission){
        // console.log('permission',permission);
        if(this.user.login_type == 'vendor')
        {
           return this.user && this.user.access.includes(permission);
        }
        else
        {
           return true;
        }
      },
      getDriverName(){
        return this.setting && this.setting.driver_name || 'Driver';
      },
      getRestaurantName(){
        return this.setting && this.setting.restaurant_name || 'Restaurant';
      },
      getCuisineName(){
        return this.setting && this.setting.cuisine_name || 'Cuisine';
      },
      getBrandName(){
        return this.setting && this.setting.brand_text_primary_lang || 'Brand';
      },
      getCatalogName(){
        return this.setting && this.setting.catalog_text_primary_lang || 'Catalog';
      },
      updateSetting(setting){
        this.setting = setting;
      },
      getTaxName(){
        return this.setting && this.setting.tax_name_primary_lang || 'Service tax';
      },
      isClientLogin(){
        return this.$auth.user.vendor_role == '1';
      },
      isSaasClientLogin(){
        return this.$auth.user.vendor_role == '4';
      },
      isWalletActive(){
        return (this.setting && this.user.wallet_status == 'active' && this.setting.wallet_status == 'active')
      },
      isWebOrderingActive(){
        let status = false;
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.webfront == 1) {
            status = true;
          }
        }
        return status;
      },
      isCallcenterActive(){
        let status = false;
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.callcenter == 1) {
            status = true;
          }
        }
        return status;
      },
      isOrderImageActive(){
        let status = false;
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.allow_order_image_status == 1) {
            status = true;
          }
        }
        return status;
      },
      isStoreSelectionActive() {
        let status = false;
        if (this.setting && this.setting.store_selection) {
          if (this.setting.store_selection == 1) {
            status = true;
          }
        }
        return status;
      },
      isProductMarket() {
        let status = false;
        if (this.setting && this.setting.product_type_id) {
          if (this.setting.product_type_id == 1) {
            status = true;
          }
        }
        return status;
      },
      isChatActive(){
        let status = false;
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.chat_status == 1) {
            status = true;
          }
        }
        return status;
      },
      isNotificationActive(){
        let status = false;
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.client_notification_status == 1) {
            status = true;
          }
        }
        return status;
      },
      isThirdpartyDeliveryServiceActive(){
        if (this.setting && this.setting.featureaccess) {
          if (this.setting.featureaccess.thirdparty_delivery_status == 1) {
            return true;
          }
        }
        return false;
      },
      isDriverAllocationOnConfirmed() {
        if (this.setting && this.setting.is_driver_allocation) {
          if (this.setting.is_driver_allocation == "true") {
            return true;
          }
        }
        return false;
      },
      getVendorDistanceMetric() {
        let distanceMetric = 'Km';
        if (this.setting && this.setting.distance_metric) {
          if (this.setting.distance_metric == 'kilometers') {
            distanceMetric = 'Km';
          } else if (this.setting.distance_metric == 'miles') {
            distanceMetric = 'Miles';
          }
        }
        return distanceMetric;
      },
      getUnreadMessages(type = ''){
        chatService.getUnreadMessage({ type : type })
          .then(response => {
            this.unreadMessages = response.data.result;
          })
      },
      getUserRoute(){
        let menuname = 'dashboard';
          if (!this.partner_login) {
            if(this.user){
              let checkdashboard = this.user.access.find(d => d == "Dashboard Read");
              if (!checkdashboard) {
                let notinMenu = [];
                if (!this.isCallcenterActive()) {
                  notinMenu.push(config.permissions.call_center_read);
                }
                if (!this.isChatActive()) {
                  notinMenu.push(config.permissions.chat_read);
                }
                if (!this.isWebOrderingActive()) {
                  notinMenu.push(config.permissions.web_layout_read);
                }
                let firstuseraccessread = this.user.access.filter(p => (p.includes('Read')  && !notinMenu.includes(p)));
                if (firstuseraccessread) {
                  let menu = menuItems.reduce((a, m) => {
                    let firstaccess = m.subItems.find(si => si.permissions.includes(firstuseraccessread[0]));
                    if (firstaccess && !a) {
                      return firstaccess.link;
                    }
                    return a;
                  }, null);
                  if (menu) {
                    menuname = menu;
                    // menuname = router.options.routes.find(r => r.path == menu).name;
                    // console.log(menuname);
                  }
                }
              }
            }
          }
          return menuname;
      }
    }
  });
  return instance;

}

export default {
  install(Vue, options = {}) {
    Vue.prototype.$auth = useAuth(options);
  }
};