<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import config from "../config";
import { menuItems } from "./menu";
import { vendormenuItems } from "./vendor";
import { clientmenuItems } from "./client";
import { saasmenuItems } from "./saas";
import { userAccessService, settingService } from "../services";
import SiteLogo from "./site-logo";
import NavBarOption from "./nav-bar-option";
import {
  get_partner_login,
  get_permitted_menus,
  get_user,
  set_user
} from "../Helper/helper";


export default {
  data() {
    return {
      config : config,
      menuItems: (this.$auth.isClientLogin() ? clientmenuItems : (this.$auth.partner_login
        ? vendormenuItems : this.$auth.isSaasClientLogin() ? saasmenuItems : 
        this.$menus || menuItems)),
      // vendormenuItems: vendormenuItems,
      permissions: this.$auth.user && this.$auth.user.access,
      setting: [],
      visible: true
    };
  },
  components: { SiteLogo, NavBarOption},
  mounted: function () {
    this.getPermissions();
    if(!this.$auth.partner_login) {
      this.getAppSetting();
    } else {
      // if (this.menuItems[5]) {
      //   if (this.menuItems[5].id = 6) {
      //     this.menuItems[5].label = this.trans.get('__JSON__.Menu time-slot');
      //   }
      // }
    }
    // this.$data.menuItems[0].subItems[1].label = this.$auth.setting.driver_name;
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      var x = paths.reduce((a, p) => (window.location.pathname.indexOf(p) !== -1?p:a),0)
      matchingMenuItem = links[paths.indexOf(x)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          // console.log("data",parent2.scrollHeight);
          parent2.scrollTop = parent2.scrollHeight + 120;
          // console.log("scrollTop",parent2.scrollTop);
          parent2.scrollIntoView(false);
          parent2.scrollIntoView({behavior: "smooth", block: "end"});

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
       
            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      } else {
        matchingMenuItem.classList.add("mm-active");
      }
    }    
  },
  methods: {
    getTranslate(val){
      // console.log('val',val);
      switch(val){
        case 'Driver':
        return this.trans.get('__JSON__.Driver', { driver: this.$auth.getDriverName() });
        case 'Vendor':
        return this.trans.get('__JSON__.Vendors', { vendor: this.$auth.getRestaurantName() });
        case 'Cuisines':
        return this.trans.get('__JSON__.Cuisines', {cuisine: this.$auth.setting.cuisine_name});
        case 'Menu time-slot':
        return this.trans.get('__JSON__.Menu time-slot');
        default:
        return this.trans.get('__JSON__.'+val);
      }
    },  
    getAppSetting(){
      // settingService.getAppSettingApi().then((response) => {
        // this.setting = response.data.result;
     
        for (let menuIndex = 0; menuIndex < this.menuItems.length; menuIndex++) {
          if (this.menuItems[menuIndex]) {
            if (this.menuItems[menuIndex].subItems) {
              for (let subIndex = 0; subIndex < this.menuItems[menuIndex].subItems.length; subIndex++) {
                if (this.menuItems[menuIndex].subItems[subIndex]) {
                  if (this.menuItems[menuIndex].subItems[subIndex].label == "Driver") {
                    this.menuItems[menuIndex].subItems[subIndex].label = this.$auth.getDriverName();
                  }
                  if (this.menuItems[menuIndex].subItems[subIndex].label == "Vendors") {
                    this.menuItems[menuIndex].subItems[subIndex].label = this.$auth.getRestaurantName();
                  }
                  if (this.menuItems[menuIndex].subItems[subIndex].label == "Brand") {
                    this.menuItems[menuIndex].subItems[subIndex].label = this.$auth.getBrandName();
                  }
                  if (this.menuItems[menuIndex].label == "Chat") {
                    if (!this.$auth.isChatActive()) {
                      this.menuItems.splice(menuIndex,1);
                    }
                  }
                  if (this.menuItems[menuIndex].label == "Call Center") {
                    if (!this.$auth.isCallcenterActive()) {
                      this.menuItems.splice(menuIndex,1);
                    }
                  }
                  if (this.menuItems[menuIndex].subItems[subIndex] && this.menuItems[menuIndex].subItems[subIndex].label == "Web Configurations") {
                    if (!this.$auth.isWebOrderingActive()) {
                      this.menuItems[menuIndex].subItems.splice(subIndex,1);
                    }
                  }
                  if (this.menuItems[menuIndex].label == "Notification History") {
                    if (!this.$auth.isNotificationActive()) {
                      this.menuItems.splice(menuIndex,1);
                    }
                  }
                  // if (this.menuItems[menuIndex].subItems[subIndex] && this.menuItems[menuIndex].subItems[subIndex].label == "Call Center") {
                  //   if (!this.$auth.isCallcenterActive()) {
                  //     this.menuItems[menuIndex].subItems.splice(subIndex,1);
                  //   }
                  // }
                  if(this.$auth.setting && this.$auth.setting.store_selection == 0) {
                    if (this.menuItems[menuIndex].subItems[subIndex] && this.menuItems[menuIndex].subItems[subIndex].label == "Business Category") {
                      this.menuItems[menuIndex].subItems.splice(subIndex,1);
                    }
                    if (
                      this.menuItems[menuIndex].subItems[subIndex] && 
                      (
                        this.menuItems[menuIndex].subItems[subIndex].label == "Brand" ||
                        this.menuItems[menuIndex].subItems[subIndex].id == 10
                      )
                    ) {
                      this.menuItems[menuIndex].subItems.splice(subIndex,1);
                    }
                  }
                }
              }
            } else {
              if (this.menuItems[menuIndex] && this.menuItems[menuIndex].label == "Catalog") {
                this.menuItems[menuIndex].label = this.$auth.getCatalogName();
              }
              // if (this.$auth.partner_login) {
              //   if (this.menuItems[menuIndex] && this.menuItems[menuIndex].label == "Selling Time slot") {
              //     this.menuItems[menuIndex].label = this.trans.get('__JSON__.Menu time-slot');
              //   }
              // }
              if (this.menuItems[menuIndex].label == "Chat") {
                if (!this.$auth.isChatActive()) {
                  this.menuItems.splice(menuIndex,1);
                }
              }
              if (this.menuItems[menuIndex].label == "Call Center") {
                if (!this.$auth.isCallcenterActive()) {
                  this.menuItems.splice(menuIndex,1);
                }
              }
              if (this.menuItems[menuIndex].label == "Notification History") {
                if (!this.$auth.isNotificationActive()) {
                  this.menuItems.splice(menuIndex,1);
                }
              }
            }
          }
        }
        //Dt.27/05/2022, Bellow are use static index so if some menu not come then it will create issue
        // if (this.menuItems[0]) {
        //   if (this.menuItems[0].subItems && this.menuItems[0].subItems[2]) {
        //     this.menuItems[0].subItems[2].label = this.$auth.getDriverName();
        //   }
        // }
        // if (this.menuItems[1]) {
        //   if (this.menuItems[1].subItems && this.menuItems[1].subItems[0]) {
        //     this.menuItems[1].subItems[0].label = this.$auth.getRestaurantName();
        //   }
        //   if (this.menuItems[1].subItems && this.menuItems[1].subItems[1]) {
        //     this.menuItems[1].subItems[1].label = this.$auth.getDriverName();
        //   }
        // }
        // if (this.menuItems[4]) {
        //   if (this.menuItems[4].subItems && this.menuItems[4].subItems[0]) {
        //     this.menuItems[4].subItems[0].label = this.$auth.getRestaurantName();
        //   }
        //   if (this.menuItems[4].subItems && this.menuItems[4].subItems[1]) {
        //     this.menuItems[4].subItems[1].label = this.$auth.getDriverName();
        //   }
        // }
      // });
      // if(this.$auth.setting && this.$auth.setting.store_selection == 0) {
      //   if (this.menuItems[5]) {
      //     if (this.menuItems[5].subItems) {
      //       if (this.menuItems[5].subItems[0] && this.menuItems[5].subItems[0].id == 18) { //Business Category
      //         this.menuItems[5].subItems.splice(0,1);
      //       }
      //     }
      //   }
      //   if (this.menuItems[1]) {
      //     if (this.menuItems[1].subItems) {
      //       if (this.menuItems[1].subItems[3] && this.menuItems[1].subItems[3].id == 10) { //Brand
      //         this.menuItems[1].subItems.splice(3,1);
      //       }
      //     }
      //   }
      // }
      // if (!this.$auth.isWebOrderingActive()) {
      //   if (this.menuItems[6]) {
      //     if (this.menuItems[6].subItems) {
      //       if (this.menuItems[6].subItems[2] && this.menuItems[6].subItems[2].id == 34) { //Web Settings
      //         this.menuItems[6].subItems.splice(2,1);
      //       }
      //     }
      //   }
      // }
      // if (!this.$auth.isCallcenterActive()) {
      //   if (this.menuItems[0]) {
      //     if (this.menuItems[0].subItems) {
      //       if (this.menuItems[0].subItems[4] && this.menuItems[0].subItems[4].id == 33) { //Call center
      //         this.menuItems[0].subItems.splice(4,1);
      //       }
      //     }
      //   }
      // }
      // if (this.$auth.partner_login) {
      //   if (this.menuItems[5]) {
      //     if (this.menuItems[5].id = 6) {
      //       this.menuItems[5].label = this.trans.get('__JSON__.Menu time-slot');
      //     }
      //   }
      // }
    },
    getPermissions() {
      if (this.$auth.partner_login) {
        this.vendormenuItems;
      } else if (this.$auth.isClientLogin()) { 
        this.clientmenuItems;
      } else if (this.$auth.isSaasClientLogin()) { 
        this.saasmenuItems;
      } else {
        const user = get_user();
        userAccessService.getAuthPermissions().then((response) => {
          // console.log("permission response".response)
          if(response.data.result.length > 0){
          this.permissions = response.data.result;
          let access = response.data.result.map((p) => p.name);
          this.$auth.updatePermissions(access);
          this.menuItems = get_permitted_menus(menuItems, response.data.result);
          Vue.prototype.$menus = this.menuItems;
        }else{
          this.$auth.logout((name) =>this.$router.push({ name }).catch(()=>{}));
        }
        });
      }
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    getBadgeCount(badge){
      switch(badge){
        case 'order':
          return this.$auth && this.$auth.orders && this.$auth.orders.length || '';
        case 'chat':
          return this.$auth && this.$auth.unreadMessages && this.$auth.unreadMessages.length || 0;
        default: 
          return '';
      }
    },

    getBadge(badges){
   //   const cout = badges.reduce((a, c) => a + this.getBadgeCount(c), 0)
     // return cout !== 0 ? cout : '';
     return '';
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div>
    <!-- ========== Logo Start ========== -->
      <SiteLogo/>
      <!-- ========== Logo End ========== -->
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
        <ul id="side-menu" class="metismenu list-unstyled">
          <template v-for="item in menuItems">
            <li
              class="menu-title"
              v-if="item.isTitle"
              :key="item.id"
              :id="'side-'+item.id"
            >{{getTranslate(item.label)}}</li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id" :id="'side-'+item.id">
              <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent"
                :class="{'has-arrow': !!(!(item.badge && getBadge(item.badge.text) !== '')), 'has-dropdown': item.badge}"
              >
                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                <span>{{getTranslate(item.label)}}</span>
                <span
                  :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                  v-if="item.badge && getBadge(item.badge.text) !== ''"
                >{{getBadge(item.badge.text)}}</span>
                <!-- <span
                  :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                  v-if="item.badge"
                >{{ (item.badge.text)}}</span> -->
              </a>

              <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
                <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                <span>{{getTranslate(item.label)}}</span>
                <span
                  :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                  v-if="item.badge && getBadge(item.badge.text) !== ''"
                >{{getBadge(item.badge.text)}}</span>
              </router-link>

              <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li v-for="(subitem, index) of item.subItems" :key="index">
                  <router-link
                    :to="subitem.link"
                    v-if="!hasItems(subitem)"
                    class="side-nav-link-ref"
                  > <span>{{ getTranslate(subitem.label) }}</span>
                    <span
                          :class="`badge badge-pill badge-${subitem.badge.variant} float-right`"
                          v-if="subitem.badge && getBadge(subitem.badge.text) !== ''"
                        >{{ getBadge(subitem.badge.text)}}</span>
                  </router-link>
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    href="javascript:void(0);"
                  >{{ getTranslate(subitem.label) }}</a>
                  <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li v-for="(subSubitem, i) of subitem.subItems" :key="100+i">
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                      >
                        <span>{{ getTranslate(subSubitem.label) }}</span>
                      </router-link>

                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      <div class="btn-side-feedback" v-if="!$auth.partner_login && this.$auth.user.vendor_role != '1'">
        <!-- <a :href="config.tripetto_url" target="_blank">
          <button class="btn btn-rounded side-btn" v-b-tooltip.hover title="Feedback">
            <i class="bx bx-chat mr-1"></i>{{ trans.get('__JSON__.Feedback') }}
          </button>  
        </a> -->
        <!-- <router-link :to="{ name: 'help' }" class="mgauto">
          <button class="btn btn-rounded side-btn" v-b-tooltip.hover title="Video Guide">
            <i class="bx bx-help-circle mr-1"></i>{{ trans.get('__JSON__.Video Guide') }}
          </button>  
        </router-link> -->
      </div>
     
    </div>
     <!-- <NavBarOption/> -->
  </div>
  <!-- Sidebar -->
</template>

<style scoped>
.mgauto {
  margin: 0 auto;
}
.metismenu {
  text-transform: capitalize;
}
#sidebar-menu ul li a {
  font-size: 14px !important;
}
#sidebar-menu ul li ul.sub-menu li a {
  font-size: 14px !important;
}
.side-nav-link-ref.active i {
    color: #ffffff !important;
    padding-bottom: 0 !important;
}
.side-nav-link-ref.router-link-active,
.side-nav-link-ref.router-link-active:hover {
    background: #556ee6 !important;
    color: #fff !important;
}

.side-nav-link-ref i:hover {
    background: #556ee6;  
    opacity:.1;
}
#sidebar-menu ul li a:hover {
    color:#545a6d;
    background: #556ee610;  
}
.simplebar-content #sidebar-menu{
  padding-bottom: 0;
}
</style>