<script>
import simplebar from "simplebar-vue";
import config from "../config";
import { replaceByDefault } from "../Helper/helper";
export default {
    components: { simplebar },
    data() {
        return {
            config: config,
            replaceByDefault: replaceByDefault,
            language: localStorage.getItem('language') || 'en'
        };
    },
    methods: {
        setLanguage(language){
            localStorage.setItem('language',language)
            this.trans.setLocale(language);
            window.location.reload();
        },
        logout() {
            if (confirm("Are you sure you want to logout?")) {
                localStorage.removeItem('language');
                window.location.reload();
                this.$auth.logout((name) => this.$router.push({ name }).catch(() => { }));
            }
        }
    }
};
</script>
<template>
    <div>
        <div class="align-items-center justify-content-between flex-column admin-notify">        
            <div class="nw-quick-hlp-btn" v-if="this.$auth.user.platform == 2">
                <span><router-link :to="{ name: 'quick-help' }"><i class="bx bx-question-mark d-none d-xl-inline-block"></i>Quick Help</router-link></span>
            </div>
        <div class="d-flex align-items-center justify-content-between">
            <div v-if="$auth.partner_login">
                <b-dropdown variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
                    <template v-slot:button-content>
                        <img class="rounded-circle header-profile-user"
                            :src="config.restauranticon + $auth.user.restaurant.icon_image" @error="replaceByDefault"
                            alt="Header Avatar" />
                        <span class="d-none d-xl-inline-block ml-1">{{ $auth.user.user_name }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <router-link :to="{ name: 'vendor.profile' }" class="dropdown-item">
                        <i class="bx bx-user font-size-16 align-middle mr-1"></i> {{trans.get('__JSON__.My Profile')}}
                    </router-link>
                    <!-- item-->
                    <router-link :to="{ name: 'change-password' }" class="dropdown-item">
                        <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> {{trans.get('__JSON__.Change Password')}}
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> {{trans.get('__JSON__.Logout')}}
                    </a>
                </b-dropdown>
            </div>
            <div v-else-if="this.$auth.user.vendor_role == '1'">
                <b-dropdown right variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
                    <template v-slot:button-content>
                        <img class="rounded-circle header-profile-user" :src="config.foodicon + $auth.setting.flat_icon"
                            @error="replaceByDefault" alt="Header Avatar" />
                        <span class="d-none d-xl-inline-block ml-1">{{ $auth.user.vendor_name }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <router-link :to="{ name: 'change-password' }" class="dropdown-item">
                        <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> Change Password
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
                    </a>
                </b-dropdown>
            </div>
            <div v-else-if="this.$auth.user.vendor_role == '4'">
                <b-dropdown right variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
                    <template v-slot:button-content>
                    <img
                        class="rounded-circle header-profile-user"
                        :src="config.foodicon+$auth.setting.flat_icon"
                        @error="replaceByDefault"
                        alt="Header Avatar"
                    />
                    <span class="d-none d-xl-inline-block ml-1">{{$auth.user.vendor_name}}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
                    <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
                    </a>
                </b-dropdown>
                <!-- <div class="dropdown d-inline-block m-nav-header">
                    <button
                    type="button"
                    class="btn header-item noti-icon right-bar-toggle toggle-right"
                    @click="toggleRightSidebar"
                    >
                    <i class="bx bx-cog bx-spin toggle-right"></i>
                    </button>
                </div> -->
            </div>
            <div v-else>
                <b-dropdown lazy variant="black" toggle-class="header-item user-profile-main" v-if="$auth.setting">
                    <template v-slot:button-content>
                        <img class="rounded-circle header-profile-user" :src="config.foodicon + $auth.setting.flat_icon"
                            @error="replaceByDefault" alt="Header Avatar" />
                        <span class="d-none d-xl-inline-block ml-1">{{ $auth.user.vendor_name }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <router-link :to="{ name: 'business-profile' }" class="dropdown-item"
                        v-if="$auth.user.parent_vendor_id == null">
                        <i class="bx bx-user font-size-16 align-middle mr-1"></i> {{trans.get('__JSON__.Business Profile')}}
                    </router-link>
                    <router-link :to="{ name: 'subscription-info' }" class="dropdown-item"
                        v-if="$auth.user.parent_vendor_id == null">
                        <i class="bx bx-wallet font-size-16 align-middle mr-1"></i> {{trans.get('__JSON__.My Billing')}}
                    </router-link>
                    <router-link :to="{ name: 'change-password' }" class="dropdown-item">
                        <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i> {{trans.get('__JSON__.Change Password')}}
                    </router-link>

                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0)" @click="logout" class="dropdown-item text-danger">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> {{trans.get('__JSON__.Logout')}}
                    </a>
                </b-dropdown>
            </div>

            <b-dropdown
                lazy
                toggle-class="header-item noti-icon"
                variant="black"
                v-if="(this.$auth.user.vendor_role == '2' || $auth.partner_login) && (this.$auth.setting.secondary_lang == 'es')"
                >
                <template v-slot:button-content>
                    <i class="mdi mdi-google-translate"></i>
                </template>
                <b-dropdown-item>
                    <a href="javascript:void(0)" class="dropdown-item" @click="setLanguage('en')">
                        English
                    </a>
                </b-dropdown-item>
                <b-dropdown-item>
                    <a href="javascript:void(0)" class="dropdown-item" @click="setLanguage('es')"> 
                        Spanish
                    </a>
                </b-dropdown-item>
            </b-dropdown>

            <div class="notify-dropdown ml-auto">   
                <b-dropdown lazy menu-class="dropdown-menu-lg p-0 " toggle-class="header-item noti-icon" variant="black"
                    v-if="this.$auth.user.vendor_role != '1' && this.$auth.user.vendor_role != '4'">
                    <template v-slot:button-content>
                        <div class="d-flex align-items-center">
                            <div class="mr-3 position-relative">
                                <i class="bx bx-bell bx-tada"></i>
                                <span class="badge badge-danger badge-pill" v-if="$auth.orders.length > 0">{{
                                    $auth.orders.length
                                }}</span>
                            </div>
                            <!-- <p class="notify-dropdown__label">Notification</p> -->
                        </div>
                    </template>
    
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">{{ trans.get("__JSON__.Notifications") }}</h6>
                            </div>
                            <div class="col-auto">
                                <router-link :to="{ name: 'orders-list' }" class="small">{{
                                    trans.get("__JSON__.View All")
                                }}</router-link>
                            </div>
                        </div>
                    </div>
                    <simplebar data-simplebar style="max-height: 230px" v-if="$auth.orders.length > 0">
                        <router-link class="text-reset notification-item" v-for="order in $auth.orders" :key="order.id" :to="{
                            name: 'orders-list',
                            query: {
                                status:
                                    order.future_delivery_date != '0000-00-00 00:00:00' &&
                                        order.order_status == 'InKitchen'
                                        ? 'Scheduled'
                                        : 'Placed',
                                date: moment(order.created_at).format('YYYY-MM-DD'),
                            },
                        }">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="bx bx-cart"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">
                                        {{
                                            trans.get("__JSON__.New Order Placed", {
                                                order_id: "#" + order.id,
                                            })
                                        }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-0">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ moment(order.created_at).fromNow() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </simplebar>
                    <simplebar data-simplebar style="max-height: 230px" v-else>
                        <a href class="text-reset notification-item">
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{ trans.choice("No Orders Found") }}</h6>
                                </div>
                            </div>
                        </a>
                    </simplebar>
                </b-dropdown>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.dropdown-menu .dropdown-menu-right {
    margin: 10px -15px;
}
.admin-notify .dropdown-toggle  span{
    max-width: 13ch;
    white-space: break-spaces;
    text-align: left;
}
.admin-notify .dropdown-toggle {
    display: flex;
    align-items: center;
}
.header-profile-user{
    flex-shrink: 0;
}
.nw-quick-hlp-btn{
    font-size: 14px;
    margin: 11px;
}
.nw-quick-hlp-btn a{
    margin-left: 20px;
}
</style>